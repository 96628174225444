@import '../../style/variables.scss';
@media (min-width: 1023px) {
  .hamburger-backdrop {
    &.open {
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      overflow: hidden;
    }
    &.closed {
      display: none;
    }
  }
  .navbar {
    top: 0;
    left: 0;
    background-color: white;
  }
  .nav-bar-container {
    width: 100vw;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;

    .nav-bar-logo {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-items: flex-start;
      align-items: center;
    }
    .nav-links {
      display: flex;
      flex-direction: column;
      justify-items: flex-end;

      .hamburger-menu {
        .nav-link {
          &.active,
          &:hover,
          &:focus {
            background-color: var(--color-blue-hover);
            color: white;
          }
          &.hidden {
            display: none;
          }
          display: flex;
          flex-direction: row;
          justify-items: center;
          justify-content: space-between;
          padding: 15px 35px;

          .nav-link-text {
            margin-left: 15px;
          }
        }
        &.open {
          background-color: white;
          display: flex;
          flex-direction: column;
          height: calc((var(--vh, 1vh) * 100));
          position: absolute;
          top: 0;
          right: 0;
        }
        &.closed {
          display: none;
        }
      }
      .hamburger-close-btn {
        height: 62px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        svg {
          height: 60px;
          width: 60px;
        }
      }
      .hamburger-toggle {
        margin-right: 20px;
      }
    }
  }
}

@media (max-width: 1022px) {
  .hamburger-backdrop {
    &.open,
    &.closed {
      display: none;
    }
  }
  .navbar {
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: white;
  }
  .nav-bar-container {
    width: 100vw;
    .nav-bar-logo {
      display: none;
    }
    .nav-links {
      .hamburger-menu {
        .nav-link {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 15px;

          &.active {
            background-color: var(--color-blue-hover);
            color: white;
          }
        }
        &.open,
        &.closed {
          background-color: white;
          display: flex;
          flex-direction: row;
          justify-content: stretch;
          .nav-link-text {
            display: none;
          }
        }
      }
      .hamburger-close-btn {
        display: none;
      }
      .hamburger-toggle {
        display: none;
      }
    }
  }
}
