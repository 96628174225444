// .search-input-container {
//   transition: max-width 0.4s;

//   &.max-w-search-btn {
//     max-width: 60px;
//   }
//   &.max-w-search {
//     max-width: 500px;
//   }
// }

.soketops-container {
  p {
    ul {
      list-style: circle;
      padding-left: 20px;
    }
    pre {
      display: block;
      padding: 5px;
      margin: 0 0 10px;
      font-size: 13px;
      line-height: 1.42857143;
      color: #333;
      word-break: break-all;
      word-wrap: break-word;
      background-color: #f5f5f5;
      border: 1px solid #ccc;
    }
  }
}
