@import './style/utils';
@import './style/variables';
@import './assets/main';

:root {
  --color-beige: #f8f0dd;
  --color-beige-dark: #d0bfae;
  --color-black: #2c2c2c;
  --color-blue: #6fe9ff;
  --color-blue-dark: #2a2859;
  --color-blue-hover: #1f42aa;
  --color-blue-light: #b3f5ff;
  --color-green: #43f8b6;
  --color-green-dark: #034b45;
  --color-green-light: #c7f6c9;
  --color-gray: #f2f2f2;
  --color-gray-light: #f9f9f9;
  --color-gray-dark: #e1e1e1;
  --color-purple-visited: #8d50b1;
  --color-purple-visited-light: #e0adff;
  --color-red: #ff8274;
  --color-red-light: #ffb4ac;
  --color-yellow: #f9c66b;
  --color-yellow-light: #ffe7bc;

  --mobile: 0;
  --tablet: 769px;
  --desktop: 1024px;
  --widescreen: 1216px;
  --fullhd: 1408px;
}

@font-face {
  font-family: 'OsloSans';
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
  src: url(./assets/fonts/OsloSans-Regular.woff2) format('woff2'),
    url(./assets/fonts/OsloSans-Regular.woff) format('woff');
}

body {
  margin: 0;
  color: $color-blue-dark;
  font-family: 'OsloSans', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @extend .text-5;
}

.osloSans {
  font-family: 'OsloSans', Arial, sans-serif;
}

a {
  text-decoration: none;
}

button {
  font-family: inherit;
  font-size: 100%;
}

hr {
  height: 1px;
  background: inherit;
  border-bottom: 1px solid #dcdcdc;
}

h1,
h2,
h3,
h4,
h5 {
  hyphens: auto;
  font-variant-ligatures: contextual;
  font-feature-settings: 'calt';
  -moz-font-feature-settings: 'calt';
  -webkit-font-feature-settings: 'calt';
  -ms-font-feature-settings: 'calt';
}

h1 {
  @extend .heading-1;
}
h2 {
  @extend .heading-2;
}
h3 {
  @extend .heading-3;
}
h4 {
  @extend .heading-4;
}
h5 {
  @extend .heading-5;
}
