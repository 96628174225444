.add-images {
  .add-images-preview-item {
    .add-images-filename {
      width: 130px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .add-images-button {
      border-width: 2px;
      &:focus,
      &:focus-within,
      &:hover {
        border-color: var(--color-blue-hover);
        color: var(--color-blue-hover);
      }
      &:active {
        transform: scale(0.95);
      }

      #innmelding-file {
        position: fixed;
        left: -9000px;
      }
    }
  }

  .add-images-preview-list {
    height: 50px;
  }
  .add-images-button {
    width: 130px;
    height: 130px;
  }

  .add-images-preview-item-img {
    object-fit: cover;
    width: 130px;
    height: 130px;
  }
}
