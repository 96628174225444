.checkbox {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  user-select: none;

  .custom-input {
    cursor: pointer;
    opacity: 0;
    position: absolute;
  }

  .checkmark {
    position: relative;
    border: 2px solid var(--color-blue-dark);
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    align-self: center;
  }

  .checkmark:after {
    content: '';
    display: none;
    position: absolute;
    border: solid white;
    border-width: 0 2px 2px 0;
    height: 10px;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 5px;
    top: 50%;
    left: 50%;
  }
  &:hover span {
    color: var(--color-blue-hover);
    border-color: var(--color-blue-hover);
  }
  .custom-input:focus ~ .checkmark {
    outline: -webkit-focus-ring-color auto 5px;
    border-color: var(--color-blue-hover);
  }
  .custom-input:focus ~ .text {
    color: var(--color-blue-hover);
  }
  .custom-input:checked ~ .checkmark:after {
    display: block;
  }
  .custom-input:checked ~ .checkmark {
    background-color: var(--color-blue-hover);
  }
  .custom-input:checked ~ span {
    font-weight: 500;
    color: var(--color-blue-dark);
    border-color: var(--color-blue-hover);
  }
  .custom-input:checked ~ span:hover {
    color: var(--color-blue-hover);
  }
}

// & input:disabled:checked ~ &__checkmark
// background-color: $osg-color-grayscale-40

// & input:disabled ~ span
// color: $osg-color-grayscale-40
// border-color: $osg-color-grayscale-40
// pointer-events: none
// background-color: none
