.marker-cluster {
  background-clip: padding-box;
  border-radius: 20px;
  background-color: rgba(44, 44, 44, 0.6);
  div {
    background-color: rgba(44, 44, 44, 0.6);
    width: 30px;
    height: 30px;
    margin-left: 5px;
    margin-top: 5px;
    text-align: center;
    border-radius: 15px;
  }
  span {
    line-height: 30px;
    color: white;
    font-family: 'OsloSans', Arial, sans-serif;
  }
}
.meldingliste_kartvisning {
  .leaflet-popup-content-wrapper {
    border-radius: 0;
    font-family: 'OsloSans', Arial, sans-serif;
    color: var(--color-blue-dark);
  }
}
