.status-color-ikke-tildelt {
  border-color: var(--color-blue-dark);
  background-color: var(--color-blue-light);
}
.status-color-ikke-behandlet,
.status-color-ukjent {
  border-color: #979797;
  background-color: #ececec;
}
.status-color-avvist {
  border-color: var(--color-red);
  background-color: #f6d3d0;
}
.status-color-under-behandling {
  border-color: var(--color-yellow);
  background-color: #ffeece;
}
.status-color-ferdig-behandlet {
  border-color: var(--color-green);
  background-color: var(--color-green-light);
}
