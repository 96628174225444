.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.close-button-section {
  position: absolute;
  bottom: 100%;
  width: 100%;
  display: flex;
}

.ReactModal__Content {
  &:focus {
    outline: none;
  }
}
