@import '../../../style/variables.scss';

$button-colors-map: (
  ('red', $color-blue-dark, $color-red),
  ('blue-dark', $color-white, $color-blue-dark),
  ('blue-light', $color-blue-dark, $color-blue-light),
  ('green-dark', $color-green-light, $color-green-dark),
  ('green-light', $color-green-dark, $color-green-light),
  ('yellow', $color-blue-dark, $color-yellow)
);

.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 60px;
  min-height: 50px;
  text-decoration: none;
  cursor: pointer;
}
.btn.btn-circle,
.btn-outline.btn-circle {
  border-radius: 50%;
  // force 1x1 ratio
  &:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    padding-top: 100%;
  }
}

@each $name, $text, $background in $button-colors-map {
  .btn.btn-#{$name} {
    color: $text;
    background-color: $background;
    border: 2px solid $background;
    &:not(:disabled) {
      &:visited {
        color: $text;
        background-color: $background;
        border: 2px solid $background;
      }
      &:focus,
      &:hover {
        color: white;
        background-color: $color-blue-hover;
        border-color: $color-blue-hover;
        text-decoration: none;
      }
    }
    &:disabled {
      background-color: $color-gray-light;
      border-color: $color-white;
      color: $color-disabled;
      cursor: not-allowed;
    }
  }
}

.btn-outline {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  min-width: 60px;
  min-height: 50px;
  text-decoration: none;
  cursor: pointer;
}
@each $name, $text, $background in $button-colors-map {
  .btn-outline.btn-#{$name} {
    &:not(:disabled) {
      color: $color-blue-dark;
      background-color: transparent;
      border: 2px solid $background;
    }
    &:focus,
    &:hover {
      color: white;
      background-color: $color-blue-hover;
      border-color: $color-blue-hover;
      text-decoration: none;
    }
    &:disabled {
      background-color: $color-gray-light;
      border-color: $color-white;
      color: $color-disabled;
      cursor: not-allowed;
    }
  }
}
