@import '../../style/variables.scss';

.rf-input-btn {
  &:not(:disabled) {
    &:focus,
    &:hover {
      color: white;
      background-color: $color-blue-hover;
      border-color: $color-blue-hover;
      text-decoration: none;
    }
  }
  &:disabled {
    background-color: $color-gray-light;
    border-color: $color-white;
    color: $color-disabled;
    cursor: not-allowed;
  }
}

.rfb_container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 134.8px 1fr;
  grid-template-areas:
    'rfb_header'
    'rfb_table';

  .rfb_header {
    .rfb_header_container {
      padding: 10px;
      max-width: calc(100vw - 20px);
    }
    grid-area: rfb_header;
  }
  .rfb_table {
    grid-area: rfb_table;
  }
  .rf-page-table-header {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-height: 89px;

    &:hover {
      display: block;
      overflow: visible;
    }
  }
}

// .rf-input {
//   input[type=number]::-webkit-inner-spin-button, 
//   input[type=number]::-webkit-outer-spin-button { 
//     -webkit-appearance: none; 
//     margin: 0; 
//   }
// }