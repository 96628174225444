@tailwind base;
@tailwind components;
@tailwind utilities;

.snarvei {
  position: fixed;
  left: -20000px;
  top: 0;
  background: #fff;
  display: block;
  z-index: 99999;
  width: 100%;
  padding: 10px 15px;
}

.snarvei:focus {
  left: 0;
}
