@import '../../../style/variables.scss';

.input-field {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;
  position: relative;
  width: 100%;
  border-radius: 0;
  input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    // height: 50px;
    border-radius: 0;
    border-style: solid;
    outline: none;
    border: none;
    padding: 5px 5px 5px 10px;
    width: 100%;
    font-size: 1em;
    &:disabled {
      background-color: $color-gray-light;
      border-color: $color-white;
      color: $color-disabled;
      cursor: not-allowed;
    }
  }
  .postfix {
    padding: 5px 5px 5px 0px;
    width: 40px;
  }
  .postfix-empty {
    padding: 0;
    width: 0;
  }

  label {
    position: absolute;
    left: 10px;
    top: 20px;
  }
}
// name -- border -- text
$input-colors: (
  ('primary', $color-blue-dark, $color-blue-dark),
  ('success', $color-green-light, $color-blue-dark),
  ('warning', $color-yellow, $color-blue-dark),
  ('danger', $color-red, $color-blue-dark)
);

@each $name, $border, $foreground in $input-colors {
  .input-field.input-field-#{$name} {
    color: $color-blue-dark;
    background-color: white;
  }
  .input-field-outline.input-field-#{$name} {
    border: solid 2px $border;
    border-right: 0;
    color: $color-blue-dark;
    background-color: white;
  }
  .input-field-outline.postfix-#{$name} {
    border: solid 2px $border;
    border-left: 0;
  }
}

@media (min-width: $desktop) {
  .input-field {
    // input {
    //   height: 60px;
    // }
    label {
      top: 24px;
    }
  }
}
