@import '../../../style/variables.scss';

.textarea-field {
  textarea {
    // height: 50px;
    outline: none;
    border: none;
    padding: 5px 5px 5px 10px;
    font-size: 1em;
    &:disabled {
      background-color: $color-gray-light;
      border-color: $color-white;
      color: $color-disabled;
      cursor: not-allowed;
    }
  }

  label {
    position: absolute;
    left: 10px;
    top: 20px;
  }
}
// name -- border -- text
$textarea-colors: (
  ('primary', $color-blue-dark, $color-blue-dark),
  ('success', $color-green-light, $color-blue-dark),
  ('warning', $color-yellow, $color-blue-dark),
  ('danger', $color-red, $color-blue-dark)
);

@each $name, $border, $foreground in $textarea-colors {
  .textarea-field.textarea-field-#{$name} {
    color: $color-blue-dark;
    background-color: white;
  }
  .textarea-field-outline.textarea-field-#{$name} {
    border: solid 2px $border;
    color: $color-blue-dark;
    background-color: white;
  }
}

@media (min-width: $desktop) {
  .textarea-field {
    // input {
    //   height: 60px;
    // }
    label {
      top: 24px;
    }
  }
}
