.arbeidsliste-beskrivelse {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.arbeidsliste_container {
  max-height: calc(var(--vh, 1vh) * 100);
  max-width: 100vw;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 62px;
  grid-template-areas:
    'content'
    'navbar';

  .content {
    grid-area: content;
  }
  .navbar {
    overflow: hidden;
    grid-area: navbar;
    border-top: 1px solid var(--color-gray);
  }
}

@media (min-width: 1024px) {
  .arbeidsliste_container {
    grid-template-rows: 62px 1fr;

    grid-template-areas:
      'navbar'
      'content';

    .navbar {
      border-top: none;
      border-bottom: 1px solid var(--color-gray);
    }
    .meldingliste_container {
      display: grid;
      grid-template-columns: 500px 1fr;
      grid-template-areas: 'listevisning kartvisning';
      .meldingliste_listevisning {
        grid-area: listevisning;
      }
      .meldingliste_kartvisning {
        grid-area: kartvisning;
      }
    }
  }
}

@media (max-width: 1023px) {
  .arbeidsliste_container {
    .meldingliste_container {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-areas: 'listevisning';
      .meldingliste_listevisning {
        grid-area: listevisning;
      }
      .meldingliste_kartvisning {
        display: none;
      }
    }
  }
}
