.grunnkart-option-grunnkart {
  background-image: url('./preview/map-preview-base.jpg');
}

.grunnkart-option-detaljert_grunnkart {
  background-image: url('./preview/map-preview-detail.jpg');
}

.grunnkart-option-satelite_grunnkart {
  background-image: url('./preview/map-preview-sattelite.jpg');
  color: #fff;
}

.leaflet-control-layers {
  display: none;
}
