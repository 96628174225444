@import "../../../style/variables.scss";

.drpdown-select {
  position: relative;
  select {
    appearance: none;
    border-radius: 0;
    height: 50px;
    width: 100%;
    padding: 5px 20px 5px 20px;
    outline: none;
    padding-right: 50px;
    text-overflow: ellipsis;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: $color-white;

    &.outline {
      border: 2px solid $color-blue-dark;
      &:hover {
        border-color: $color-blue-hover;
      }
      &:focus {
        border-width: 4px;
        padding-left: 18px;
        padding-right: 18px;
        border-color: $color-blue-hover;
        padding-right: 48px;
      }
    }
    &:disabled {
      color: #999999;
      border-color: #999999;
      cursor: not-allowed;
    }
  }
  &:after {
    color: inherit;
    content: url(../../../assets//osg-icons/chevron-thin-down.svg);
    right: 14px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    width: 32px;
    height: 32px;
  }
}
