.filter {
  position: fixed;
  top: 0;
  max-width: 500px;
  width: 75vw;
  height: 100vh;
  overflow-y: scroll;
  transition: right 300ms;
  padding: 0;
  &.open {
    left: 0;
  }
  &.closed {
    display: hidden;
    left: -150vw;
  }
}
.filter-backdrop {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  &.open {
    left: 0;
  }
  &.closed {
    visibility: hidden;
    left: -100%;
  }
}
