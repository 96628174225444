.react-datepicker-popper {
  .react-datepicker {
    .react-datepicker__triangle {
      border-bottom-color: var(--color-blue-dark) !important;
    }
    // font-family: 'OsloSans', Arial, sans-serif;
  }
}
.react-datepicker-wrapper {
  .react-datepicker__input-container {
    .datetime_input {
      max-width: 185px;
    }
    .date_input {
      max-width: 140px;
    }
  }
}

.react-datepicker-popper {
  z-index: 130 !important;
  .datetime_calendar {
    background-color: white;
    display: flex;
    .react-datepicker__time {
      .react-datepicker__time-box {
        background-color: white;
      }
    }
  }
}
