$color-beige: #f8f0dd;
$color-beige-dark: #d0bfae;
$color-black: #2c2c2c;
$color-blue: #6fe9ff;
$color-blue-dark: #2a2859;
$color-blue-hover: #1f42aa;
$color-blue-light: #b3f5ff;
$color-green: #43f8b6;
$color-green-dark: #034b45;
$color-green-light: #c7f6c9;
$color-gray: #f2f2f2;
$color-gray-light: #f9f9f9;
$color-purple-visited: #8d50b1;
$color-purple-visited-light: #e0adff;
$color-red: #ff8274;
$color-yellow: #f9c66b;
$color-white: #fff;

$color-grayscale-10: darken(white, 10%);
$color-grayscale-20: darken(white, 20%);
$color-grayscale-30: darken(white, 30%);
$color-grayscale-40: darken(white, 40%);
$color-grayscale-50: darken(white, 50%);
$color-grayscale-60: darken(white, 60%);
$color-grayscale-70: darken(white, 70%);
$color-grayscale-80: darken(white, 80%);
$color-grayscale-90: darken(white, 90%);

$color-disabled: $color-grayscale-40;

$gap: 32px !default;

$tablet: 769px !default;
$desktop: 959px + (2 * $gap) !default;
$widescreen: 1152px + (2 * $gap) !default;
$fullhd: 1344px + (2 * $gap) !default;

$osg-cubic-bezier: cubic-bezier(0.22, 0.57, 0.25, 1);

// name -- background -- foreground
$named-colors: (
  ('disabled', $color-gray-light, $color-disabled),
  ('muted', $color-grayscale-10, $color-grayscale-80),
  ('primary', $color-blue-light, $color-green-dark),
  ('success', $color-green-light, $color-green-dark),
  ('warning', $color-yellow, $color-green-dark),
  ('danger', $color-red, $color-green-dark)
);
